<template>
<div class="table-box">
  <div class="table-container">
    <el-row :gutter="24">
      <el-col :span="24">
        <el-row class="float">
          <el-button type="primary" icon="el-icon-plus" @click="addUser()" v-if="action.adduser">添加</el-button>
          <!-- <el-button type="primary" icon="el-icon-plus" >导出</el-button> -->
        </el-row>
        <el-row class="float">
          <el-input v-model="pullUser" style="width:200px;" placeholder="通过帐号添加成员" v-if="action.pulluser||action.adduser"></el-input>
          <el-button type="primary" icon="el-icon-plus" @click="addUserToProject()" v-if="action.pulluser">邀请系统用户</el-button>
        </el-row>
        <el-row class="floatLeft margin">
          <el-input v-model="searchValue" style="width:200px;" placeholder="请输入用户帐号或昵称"></el-input>
          <el-button type="primary" icon="el-icon-search" @click="search()">搜索</el-button>
        </el-row>
        <el-table
          id="out-table"
          ref="singleTable"
          stripe
          :data="userList"
          highlight-current-row
          style="width:100%;"
          border
        >
          <el-table-column align="left" label="用户名称">
            <template slot-scope="scope">{{ scope.row.userName }}</template>
          </el-table-column>
          <el-table-column align="left" label="昵称">
            <template slot-scope="scope">{{ scope.row.nickName }}</template>
          </el-table-column>
          <el-table-column label="操作" v-if="action.updateuser||action.deleterole">
            <template slot-scope="scope">
              <i class="el-icon-edit" @click="updateUser(scope.row)" v-if="action.updateuser"></i>
              <i class="el-icon-delete" @click="deletUser(scope.$index,userList,scope.row)" v-if="action.deleterole"></i>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-dialog
      :title="addOrEdit=='add'?'添加用户':'编辑用户'"
	  :close-on-click-modal="false"
      :visible.sync="addUserDialog"
      width="30%">
      <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="100px" label-position="left" style="text-align: left;">
        <el-form-item label="用户名" prop="userName">
          <el-input v-if="addOrEdit=='add'" v-model="ruleForm.userName" placeholder="用户名称"/>
          <el-input v-else v-model="ruleForm.userName" placeholder="用户名称" readonly=""/>
        </el-form-item>
        <el-form-item label="昵称" prop="nickName">
          <el-input v-model="ruleForm.nickName" placeholder="用户昵称" />
        </el-form-item>
        <el-form-item label="密码" type="password" prop="Password">
          <el-input v-model="ruleForm.password" type="password" placeholder="密码" />
        </el-form-item>
        <el-form-item label="角色">
          <el-tree v-if="isShowTree" :data="allRole" @check-change="handleRoleChange" show-checkbox node-key="roleId" :props="{children:'children',label: 'roleName' }" :default-checked-keys="defaultCheckedKeys">
          </el-tree>
        </el-form-item>
        <!-- <el-form-item label="组织">
          <el-tree v-if="isShowOrganizTree" :data="allOrganiz" @check-change="handleOrganizChange" show-checkbox node-key="id" :props="{children:'children',label: 'name' }" :default-checked-keys="defaultCheckedOrganizKeys">
          </el-tree>
        </el-form-item> -->
      </el-form>
      <div style="text-align:right;">
        <el-button type="danger" @click="addUserDialog=false">取消</el-button>
        <el-button type="primary" class="save" @click="submitForm('ruleForm')">保存</el-button>
      </div>
    </el-dialog>
  </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'User',
  data () {
    return {
      userList: [],
      userListTemp: [],
      addUserDialog: false,
      ruleForm: {
        userName: '',
        nickName: '',
        password: ''
      },
      searchValue: '',
      pullUser: '',
      addOrEdit: 'add',
      checkRole: [],
      checkOrganiz: [],
      allRole: [{
        roleName: '全部角色',
        roleId: -1,
        children: []
      }],
      allOrganiz: [{
        name: '全部组织',
        id: -1,
        children: []
      }],
      rules: {
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' }
        ],
        userName: [
          { required: true, message: '用户名不能为空', trigger: 'blur' }
        ],
        nickName: [
          { required: true, message: '用户昵称不能为空', trigger: 'blur' }
        ]
      },
      isShowTree: false,
      isShowOrganizTree: false,
      defaultCheckedKeys: [],
      defaultCheckedOrganizKeys: []
    }
  },
  created () {
    this.getUsers()
  },
  computed: {
    ...mapState('action', {
      action: 'action'
    })
  },
  methods: {
    getUsers () {
      this.$axios.get('/api/Role/GetAllRole').then(res => {
        this.allRole[0].children = res
      })
      this.$axios.get('/api/User/GetUsers').then(res => {
        this.userList = res
        this.userListTemp = res
      })
      this.$axios.get('/api/Organization/GetOrganizList').then(res => {
        this.allOrganiz[0].children = res
      })
    },
    getRole () {
      this.$axios.get('/api/Role/GetAllRole').then(res => {
        this.allRole[0].children = res
      })
    },
    updateUser (user) {
      this.addOrEdit = 'edit'
      this.isShowTree = false
      this.defaultCheckedKeys = []
      this.checkRole = user.roleList
      var key = []
      this.ruleForm = this.$utils.deepClone(user)
      user.roleList.forEach(e => {
        key.push(e.roleId)
      })
      this.defaultCheckedKeys = key
      setTimeout(() => { this.isShowTree = true }, 100)
      setTimeout(() => { this.isShowOrganizTree = true }, 100)

      this.addUserDialog = true
    },
    addUser () {
      this.ruleForm = {
        userName: '',
        nickName: '',
        password: ''
      },
      this.addOrEdit = 'add'
      this.checkRole = []
      this.defaultCheckedKeys = []
      this.isShowTree = false
      setTimeout(() => { this.isShowTree = true }, 100)

      this.checkOrganiz = []
      this.defaultCheckedOrganizKeys = []
      this.isShowOrganizTree = false
      setTimeout(() => { this.isShowOrganizTree = true }, 100)
      this.addUserDialog = true
    },
    search () {
      var str = this.searchValue
      if (str == '') {
        this.userList = this.userListTemp
        return
      }
      this.userList = this.userListTemp.filter(n => {
        return n.userName.indexOf(str) > -1 || n.nickName.indexOf(str) > -1
      })
    },
    submitForm (ruleForm) {
      var _this = this
      this.$refs[ruleForm].validate(valid => {
        if (valid) {
          var formData = new FormData()
          formData.append('NickName', _this.ruleForm.nickName)
          if (_this.addOrEdit == 'add') {
            formData.append('UserName', _this.ruleForm.userName)
            formData.append('Password', _this.ruleForm.password)
            _this.checkRole.forEach(element => {
              if (element.roleId > -1) {
                formData.append('RoleIds[]', element.roleId)
              }
            })
            _this.$axios.post('/api/User/AddUser', formData).then(res => {
              var resultUserId = res.id
              var user = {}
              user.userId = resultUserId
              user.userName = _this.ruleForm.userName
              user.nickName = _this.ruleForm.nickName
              user.roleList = []
              _this.checkRole.forEach(element => {
                if (element.roleId > -1) {
                  var role = {}
                  role.roleId = element.roleId
                  role.roleName = element.roleName
                  user.roleList.push(role)
                }
              })
              _this.userListTemp.push(user)
              _this.$message({
                type: 'success',
                message: '添加用户成功'
              })
              _this.addUserDialog = false
            })
          } else {
            var oldRole = _this.ruleForm.roleList
            var newRole = _this.checkRole
            _this.ruleForm.RoleAddIds = []
            _this.ruleForm.RoleDeletIds = []
            oldRole.forEach(element => {
              // 在新选择的所有权限中 如果没有找到原来的，说明是被删除的
              var index = newRole.findIndex(n => n.roleId == element.roleId)
              if (index == -1) {
                formData.append('RoleDeletIds[]', element.roleId)
              }
            })
            newRole.forEach(element => {
              // 在原有的所有权限中 如果没有找到的项，说明是新增的
              var index = oldRole.findIndex(n => n.roleId == element.roleId)
              if (index == -1 && element.roleId != -1) {
                formData.append('RoleAddIds[]', element.roleId)
              }
            })
            formData.append('Id', _this.ruleForm.userId)
            formData.append('NickName', _this.ruleForm.nickName)
            _this.$axios.post('/api/User/UpdateUser', formData).then(res => {
              _this.ruleForm.roleList = _this.checkRole
              var indexT = _this.userListTemp.findIndex(n => n.userId == _this.ruleForm.userId)
              _this.userListTemp.splice(indexT, 1, _this.ruleForm)

              _this.ruleForm = {
                userName: '',
                nickName: '',
                password: ''
              }

              _this.$message({
                type: 'success',
                message: '修改用户成功'
              })

              _this.checkRole = []
              _this.isShowTree = false
              _this.defaultCheckedKeys = []
              setTimeout(() => { _this.isShowTree = true }, 100)
              _this.addUserDialog = false
            })
          }
        }
      })
    },
    addUserToProject () {
      if (!this.pullUser) { // 未输入用户名
        this.$message({
          type: 'warning',
          message: '请输入要邀请的用户!'
        })
      } else {
        this.$axios.get(`/api/Project/ProjectUser?userName=${this.pullUser}`).then(res => {
          this.$message({
            type: 'success',
            message: '邀请成功'
          })
          this.userListTemp.push(res)
        })
      }
    },
    handleRoleChange (e, ischeck) {
      if (ischeck) {
        this.checkRole.push(e)
      } else {
        this.checkRole = this.checkRole.filter(item => item.roleId !== e.roleId)
      }
    },
    handleOrganizChange (e, ischeck) {
      if (ischeck) {
        this.checkOrganiz.push(e)
      } else {
        this.checkOrganiz = this.checkOrganiz.filter(item => item.id !== e.id)
      }
    },
    deletUser (index, rows, user) {
      this.$confirm('此操作将删除该用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.get('/api/Project/DeleteProjectUser?userId=' + user.userId).then(res => {
          rows.splice(index, 1)
          this.$message({
            type: 'success',
            message: '用户移除项目成功!'
          })
        })
      }).catch(() => {
      })
    }

  }
}
</script>

<style scoped>
.float {
  float: right;
  margin-bottom: 20px;
}
.float > button {
  margin-left: 10px;
  background: #0094CE;
  border: none;
}
.floatLeft {
  float: left;
  margin-bottom: 20px;
}
.floatLeft > button {
  margin-left: 10px;
  background: #475065;
  border: none;
}
.margin {
  margin-right:20px
}
	.table-box {
		padding: 15px 25px;
		height: calc(100vh - 100px);
		box-sizing: border-box;
		background-color: #e7e7e7;
	}

	.table-container {
		display: flex;
		height: calc(100vh - 170px);
		flex-wrap: wrap;
		overflow-y: auto;
		align-content:flex-start;
		box-shadow: 0px 2px 15px 0px rgba(187, 187, 187, 0.5);
		border-radius: 8px;
		background-color: #FFFFFF;
		padding: 20px;
	}
  /deep/.save{
		background-color: #475065;
		border: none;
	}
</style>
